import {IMAGE_LOADER, ImageLoaderConfig} from "@angular/common";
import {environment} from "../../../environments/environment";

export const provideCustomImageLoader = () => {
  return {
    provide: IMAGE_LOADER,
    useValue: (config: ImageLoaderConfig) => {
      return `${environment.api}/${config.src}?t=${environment.tenant}&width=${config.width}&placeholder=${config.isPlaceholder || false}`;
    },
  }
}
